<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100 left-detail">
        <div class="md-layout-item md-size-100">
          <div class="chat-container md-scrollbar md-theme-default"  ref="main">
            <div class="chat_box" ref="chatScroll">
              <div class="chat_to">
                {{$route.query.scene | filterScene}}

              </div>
            </div>
            <div ref="mianscroll">
              <div v-for="(item,index) in historyChat" :key="index">
                <div class="dateTime">{{item.InputTime | dateForm }}</div>
                <div style="display: flex;justify-content: flex-end;width: 100%;">
                  <div class="chat_from md-elevation-4">
                    <div class="questionIfo" v-html="item.Question"></div>
                    <div class="chat_from_copy-box md-elevation-1 md-raised" >
                      <span @click="copyText(item.Question)">
                        <md-icon class="content_copy ">content_copy</md-icon>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="dateTime">{{item.RecievedTime.Int64 | dateForm}}</div>
                <div class="chat_to md-elevation-4"  v-if="index !== historyChat.length -1">
                  <div v-html="`${item.Answer.String}`.replace(/\n/g,'<br/>')"></div>
                  <div class="chat_from_copy-box md-elevation-1" @click="copyText(item.Answer.String)">
                    <md-icon class="content_copy" >content_copy</md-icon>
                  </div>
                </div>
                <div class="chat_to md-elevation-4"  v-else>
                  <div v-html="obj.output?`${obj.output}`.replace(/\n/g,'<br/>'):`${item.Answer.String}`.replace(/\n/g,'<br/>')"></div>
                  <div class="chat_from_copy-box md-elevation-1" @click="copyText(item.Answer.String)">
                    <md-icon class="content_copy" >content_copy</md-icon>
                  </div>
                </div>
              </div>
              <div style="display: flex;justify-content: flex-end;width: 100%;" v-if="isLoading">
                <div class="chat_from md-elevation-4">
                  <div class="questionIfo" v-html="lastQuestion"></div>
                </div>
              </div>
              <div class="chat_to thinking-box" v-if="isLoading">
                <span>正在思考中 <a style="color: red;">(大概需要40s)</a></span>
                <span>请耐心等待，不要关闭当前页面，否则可能会生成中断。</span>
                <div class="parentBox">
                  <div class="contantBox"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="send-box">
          <div class="action-box" style="align-items: center;">
            <div class="clear-history-box" @click="removeHistory">
            <span class="material-icons">
              restart_alt
            </span>
              <span>清除历史</span>
            </div>

            <div class="info-box" @click="showDialog = true">
              <span class="material-icons md-raised">lightbulb</span>
              <span>提示词</span>
            </div>
          </div>
          <div style="margin-top: 10px;">
            <div v-if="prefixTitle" style="margin-left:10px;padding:3px;display: flex;align-items: center;color: #5f5f5f;">
              已选择模板：{{prefixTitle}}
              <span class="material-icons md-raised" style="color: #5f5f5f;cursor: pointer;font-size: 12px;margin-left: 4px;" @click="deletePrefix">close</span>
            </div>
          </div>
          <md-field style="margin: 0 !important;background: #eee !important;">
            <md-textarea v-model="description" :md-autogrow="true" class="chat-text-input" placeholder="请输入新的主题关键词"></md-textarea>
            <md-button class="md-fab md-success" @click="sendContent">
              <md-icon class="send">send</md-icon>
            </md-button>
          </md-field>
        </div>
      </div>
      <md-snackbar md-position="center" :md-duration="4000" :md-active.sync="showSnackbar" md-persistent>
        <span>复制成功!</span>
      </md-snackbar>

      <md-drawer class="md-right" :md-active.sync="showDialog" v-if="showDialog">
<!--        <md-toolbar class="md-transparent" md-elevation="0">-->
<!--          <span class="md-title">Favorites</span>-->
<!--        </md-toolbar>-->
        <div class="tips-title">
          <span>推荐提示词</span>
          <div @click="showDialog = false">
            <span class="material-icons">
            close
            </span>
          </div>
        </div>
        <div class="tips-add-box">
<!--          <span></span>-->
<!--          <div @click="addPrompt" class="tips-add&#45;&#45;inner-box">-->
<!--            <span class="material-icons">-->
<!--            add-->
<!--            </span>-->
<!--            <span>新增提示词</span>-->
<!--          </div>-->
        </div>
        <div class="tipsListBox">
          <ul>
            <li v-for="(item,index) in tipsList" :key="index" class="md-elevation-5">
              <div class="tipsListInnerBox" @click="toLookMoreTip(item)">
                <div class="left-tips-box">
                  <p class="tips-note-title">{{item.Title.String}}</p>
                  <p class="tips-note">{{item.BriefTemplate.String}}</p>
                </div>
                <div class="tips-look-btn">
                  查看提示词
                </div>
              </div>
              <div class="tipsListDetailBox" v-if="item.show">
                <p>
                  <span class="tips-note">前置词：</span>
                  <span>{{item.PrefixTemplate.String}}</span>
                </p>
                <p>
                  <span class="tips-note">关键字：</span>
                  <span>{{item.Hotkey.String}}</span>
                  <span style="color: #7365ff">（把 [ ] 内的关键字替换成你的内容。）</span>
                </p>
                <div style="display: flex;justify-content: space-between;"><span></span><span @click="useTip(item)">使用</span></div>
              </div>
            </li>
          </ul>
        </div>
      </md-drawer>
  </div>
  </div>
</template>

<script>
import moment from 'moment';
import EasyTyper from 'easy-typer-js';
import * as API from '@/api/index';
import store from "@/store";
import { driver } from 'driver.js';
import "driver.js/dist/driver.css";
const driverObj = driver({
  popoverClass: 'driverjs-theme'
});

import {getAllCateAndScene, getAllTipsList, getChatHistory, openChat} from "@/api/index";
// const typed = new EasyTyper(obj, input, fn, hooks);
export default {
  name: "detail",
  components: {
  },
  filters:{
    // 格式化时间
    dateForm:function (el) {
      moment.locale('zh-cn');
      var fromDate = moment(el).format('YYYY-MM-DD HH:mm:ss')
      const onHourBefore = moment.utc().subtract(24,"hours").format("YYYY,MM-DD HH:mm:ss");
      const onHourNow = moment.utc().subtract(0,"hours").format("YYYY,MM-DD HH:mm:ss");
      const startWeek = moment().weekday(1);
      const endWeek = moment().weekday(7);
      if(moment(el).isBetween(startWeek,endWeek)){
        // 表示时间在24小时内
        return moment().format('dddd HH:mm')
      }else{
        return moment(el).format('YYYY-MM-DD HH:mm:ss');
      }
    },
    // 格式化匹配场景下的提示词
    filterScene(val){
      switch (val) {
        default:
          return '你好！有什么我可以帮助你的吗?';
      }
    }
  },
  data() {
    return {
      description: '',
      chooseItem: {},
      exampleList: [
        {
          name:'实例1',
          desc:'地球，外星人，哈哈，蹦跳，欢乐，漫天的糖果，小朋友，满天飞',
        },
        {
          name:'实例2',
          desc:'请把以下要点，扩展成一篇文章\n' +
              '\n' +
              '      1.关于国家“一带一路”重要战略的深入研究，关于“一带一路”沿线国家和地区经济和国际关系情况对高校志愿服务的影响；\n' +
              '      2.“一带一路”背景下，高校志愿服务国际化发展面临的困境、挑战和机遇；\n' +
              '      3.高校志愿服务国际化新模式的构建和发展；\n' +
              '      4.国际化志愿服务发展对青年志愿者成长发展的利好影响',
        },
        {
          name:'实例3',
          desc:'她，他，教室，黑板，作业本，不敢说话。十年后，街边，路过，说你好，微笑',
        }
      ],
      showContent: false,
      chatDetail: '',
      isLoading: false,
      showChatRespond: false,
      obj: { // 打字机效果的基础参数
        output: '',
        isEnd: false,
        speed: 30,
        singleBack: false,
        sleep: 0,
        type: 'normal',
        backSpeed: 40,
        sentencePause: false
      },
      historyChat: [],// 历史记录的列表
      showSnackbar: false,
      position: 'center',
      duration: 4000,
      isInfinity: false,
      showDialog: false,
      tipsList: [],
      modalList: [],
      lastQuestion:'',
      tipsContent: '',
      promptTemplateId: '',//选择的templateId
      prefixTemplate: '',//选择的templateContent
      prefixTitle: '',// 提示词模板名称
    }
  },
  methods:{
    // 点击删除提示词
    deletePrefix() {
      this.prefixTemplate = ''
      this.promptTemplateId = ''
      this.prefixTitle = ''
      this.description = ''
    },
    // 点击清除历史
    removeHistory(){
      var obj = {
        userName: store.state.userName,
        category: this.$route.query.category,
        scene: this.$route.query.scene
      }
      API.clearChatHistory(obj).then(res=>{
        if(res.code == 200){
          this.historyChat = []
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    // 点击查看提示词
    toLookMoreTip(item){
      this.tipsList.forEach(item=>{
        item.show = false
      })
      item.show = !item.show
      this.$forceUpdate()
    },
    // 点击复制
    copyText(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.showSnackbar = true
      })
    },
    // 点击实例
    toSend(item,index){
        this.chooseItem = item
        this.description = item.desc
    },
    // 点击生成
    sendContent(){
      if(this.isLoading){
        return;
      }
      this.showContent = true
      var now =  moment().valueOf();
      if(this.promptTemplateId){
        var obj = {
          question:this.description,
          inputTime: now,
          userName: store.state.userName,
          wechatName:store.state.wechatNickname,
          category: this.$route.query.category,
          scene: this.$route.query.scene,
          promptTemplateId: this.promptTemplateId
        }
      }else{
        var obj = {
          question: this.description,
          inputTime: now,
          userName: store.state.userName,
          wechatName: store.state.wechatNickname,
          category: this.$route.query.category,
          scene: this.$route.query.scene,
        }
      }

      if(this.description.trim() == ''){
        return
      }
      this.isLoading = true
      var str = this.prefixTemplate + this.description
      this.lastQuestion = str
      this.description = null
      this.showChatRespond = true
      this.prefixTemplate = ''
      this.promptTemplateId = ''
      this.prefixTitle = ''
      this.$nextTick(() => {
        this.$refs.main.scrollTop = this.$refs.mianscroll.scrollHeight + this.$refs.chatScroll.scrollHeight
      })
      API.openChat(obj).then(res=>{
        if(res.code == 200){
          const typed = new EasyTyper(this.obj,res.data.content);
          this.isLoading = false
          this.$nextTick(() => {
            let scrollEl = this.$refs.mianscroll;
            scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: 'smooth' });
          });
          this.getChatHistory()
        }else{
          alert(res.message)
        }
      }).catch(err=>{
        alert(err)
      })
    },
    // 获取所有的聊天历史
    getChatHistory() {
      var obj = {
        userName: store.state.userName,
        category: this.$route.query.category,
        scene: this.$route.query.scene
      }
      API.getChatHistory(obj).then(res=>{
        if(res.code == 200){
          this.historyChat = res.data.list
          // var container = document.getElementsByClassName('chat-container'); //替换你的dom元素
          // container.scrollTo(0, container.scrollHeight - container.clientHeight);
          this.$nextTick(() => {
            this.$refs.main.scrollTop = this.$refs.mianscroll.scrollHeight + this.$refs.chatScroll.scrollHeight
          })
          this.isLoading = false
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    // 获取所有当前的分类下面的所有的提示词
    getAllSubCate(){
      var obj = {
        category: this.$route.query.category,
        scene: this.$route.query.scene
      }
      API.getAllCateAndScene(obj).then(res=>{
        if(res.code == 200){
          this.tipsList = res.data.list
          if(this.tipsList&&this.tipsList.length>0){
            this.tipsList.forEach(item=>{
              item.show = false
            })
          }
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    // 点击使用
    useTip(item){
      this.showDialog = false
      this.description = item.Hotkey.String
      this.promptTemplateId = item.Id
      this.prefixTemplate = item.PrefixTemplate.String
      this.prefixTitle = item.Title.String
    },
    addPrompt() {
    }
  },
  mounted(){
    this.getChatHistory()
    this.getAllSubCate()
    if(!sessionStorage.getItem('hasLoad')){
      const driverObj = new driver({
        popoverClass: 'driverjs-theme',
      })
      driverObj.highlight({
        element: '.info-box',
        popover: {
          title: '提示：',
          description: '点击可以使用系统定义的提示词模板哦~ ',
          side: "top",
          align: 'start',
          showButtons : ['next', 'previous'],
          closeBtnText: '121',
          doneBtnText: '122',
          nextBtnText : '我知道',
          prevBtnText : '124',
          allowClose: true,
          opacity: 0.75,
          onNextClick:()=>{
            driverObj.destroy()
          }
        }
      });
      sessionStorage.setItem('hasLoad',true)
    }
  }
}
</script>

<style scoped>

.md-layout-item{
  display: flex;
  flex-wrap: wrap;
}
.md-layout-item{
}
.left-detail{
  border-radius: 3px;
  padding:0.2rem 0.2rem;
}
.left-inner-box{
  display: flex;
  align-items: center;
  height: 4.5rem;
  line-height: 4.5rem;
}
/*.left-inner-box .md-icon{*/
/*  margin: 0;*/
/*  padding: 0;*/
/*  font-size: 3rem !important;*/
/*  line-height: 3rem !important;*/
/*  width: 3rem !important;*/
/*  height: 3rem !important;*/
/*  color: #50aa54 !important;*/
/*}*/
.left-inner-box p {
  font-size: 1.5rem !important;
  line-height: 1.5rem !important;
  margin-left: 0.5rem;
  margin-bottom: 0px;
}
.left-content,.right-content{
  padding:1rem 0;
  border-top:1px solid #e4e4e8;
  margin-top: 1rem;
}
.left-content-textaera .md-textarea{
  width: 100% !important;
  min-height:20rem;
  margin-top: 1rem;
  border:1px solid #d8d8d8;
  resize: none;
  padding:1rem;
  font-size: 1.2rem;
}
.left-inner-detail{
  width: 100%;
}
.left-content .md-btn{
  margin-right: 10px;
}
.left-content-btn{
  width: 100%;
}
.left-content-btn .md-button{
  width: 100%;
  height: 3rem;
  font-size: 1.2rem;

}
.left-content-btn span{
  margin-left: 1.5rem;
}
.right-detail{
  margin-left: 2%;
  background: #ffffff;
  border-radius: 3px;
  padding:1.2rem;
}
.right-content{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.right-content img{
  width: 35%;
}
.formChat{
  background-color: #7365ff;
  border-radius: 20px;
  border-bottom-right-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #fafafa;
  display: inline-block;
  font-size: .16rem;
  letter-spacing: .6px;
  line-height: 1.75;
  max-width: 87%;
  padding: 0.13rem 0.19rem;
  position: relative;
  text-align: left;
  word-break: break-all;
}
.chat-text-input {
  width: 100% !important;
  /*-webkit-box-flex: 1 !important;*/
  -webkit-tap-highlight-color: transparent !important;
  background-color: #fff !important;
  border-radius: 1.2rem !important;
  box-shadow: 0 0.4rem 0.4rem 0 rgba(179, 178, 255, .3) !important;
  /*-webkit-flex: 1 !important;;*/
  /*flex: 1 !important;;*/
  font-size: 1.2rem;
  font-family: Arial, Helvetica, sans-serif !important;
  margin-left: 0.12rem !important;
  overflow: hidden !important;
  padding:1rem 80px 1rem 1rem!important ;
  position: relative !important;
}
.md-field:before{
  background: transparent !important;
}
.md-field:after{
  height: 0px;
}
.send-icon{
  position: absolute;
  right: 2.3rem;
  top: 20px;
  transform: translateY(-50%);
}
.chat_from{
  background-color: #7365ff;
  border-radius: 20px;
  border-bottom-right-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #fafafa;
  display: inline-block;
  letter-spacing: .6px;
  line-height: 2rem;
  max-width: 87%;
  padding: 0.5rem 0.8rem;
  position: relative;
  text-align: left;
  word-break: break-all;
  justify-content: flex-end;
  margin-top: 1rem;
}
.chat_from .chat_from_copy-box{
  display: none;
  position: absolute;
  background: white;
  padding: 5px;
  top: -30px;
  right: 10px;
  border-radius: 5px;
  font-size: 10px;
}
.chat_from:hover .chat_from_copy-box,.chat_to:hover .chat_from_copy-box{
  display: block;
}

.chat_to{
  background-color: #fff;
  border: 0.01rem solid transparent;
  border-radius: 4px 20px 20px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1e1f24;
  /*line-height: .26rem;*/
  width: fit-content;
  max-width: calc(100vw - 3rem);
  min-height: 2rem;
  line-height: 2rem;
  /*min-width: 0.72rem;*/
  padding: 0.5rem 0.8rem;
  position: relative;
  text-align: justify;
  margin-top: 1rem;
}
.chat_to .chat_from_copy-box{
  display: none;
  position: absolute;
  background: #fff;
  padding: 5px;
  top: -30px;
  right: 10px;
  border-radius: 5px;
  font-size: 10px;
  z-index: 11;
}
.example_box{
  background: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
}
.example_box_in{
  display: flex;
  width: 100%;
}
.example_box_chat{
  padding: 0.13rem 0.19rem;
  flex:1;
  background: #f7f7fe;
  border-radius: 18px;
  cursor: pointer;
  display: block;
  height: 120px;
  margin-left: 17px;
  padding: 16px 21px 21px 20px;
  position: relative;
}
.example_box_chat_titled{
  font-family: Arial,sans-serif;
  font-weight: 600;
}
.example_box_chat_btn{
  -webkit-tap-highlight-color: transparent;
  background: #ebe9ff;
  bottom: 21px;
  color: #5240ff;
  cursor: pointer;
  font-family: PingFangSC-Semibold,Arial,sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 18px;
  position: absolute;
  right: 20px;
  text-align: center;
  border-radius: 2rem;
}
.example_box_chat_btn:hover{
  background: #dcd9ff;
}
.send-box{
  position: relative;
  bottom: 10px;
  padding: 15px;
  width: 100%;
}
.send-box .md-fab .md-ripple{
  padding: 0 !important;
}
.send-box button{
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-container{
  width: 100%;
  height: calc(100vh - 250px);
  overflow-y: auto;
  padding: 15px;
  box-sizing: content-box;
  margin-bottom: 10px;
}
.dateTime{
  text-align: center;
  margin-top: 1rem;
  color: #848691;
}
.action-box{
  display: flex;
  width: 100%;
  background: #eeeeee;
}
.clear-history-box{
  height:34px;
  align-items: center;
  border-radius: 0.9rem;
  min-width:100px;
  color: #1f1f1f;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  line-height: 1;
  opacity: 1;
  cursor: pointer;
  background: #7365ff;
  color: #fff !important;
}
.info-box{
  height:34px;
  align-items: center;
  border-radius: 0.9rem;
  min-width:100px;
  color: #1f1f1f;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  line-height: 1;
  opacity: 1;
  margin-left: 1rem;
  cursor: pointer;
  background: #fd9d18;
  color: #fff !important;
}
.clear-history-box:active{
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
}
.info-box:active{
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
}
.md-tabs .md-button{
  color: #495057;
}
.thinking-box{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
}
</style>
<style lang="scss" scoped>
.parentBox {
  height: 100%;
  margin-left: 10px;
  margin-top: 10px;
  .contantBox {
    width: 40px;
    height: 2px;
    -webkit-mask: linear-gradient(90deg, #000 70%, #0000 0) 0/10%;
    background: linear-gradient(rgb(115, 101, 255) 0 0) 0/0% no-repeat #ddd;
    animation: cartoon 1.5s infinite steps(6);
  }

  @keyframes cartoon {
    100% {
      background-size: 120%;
    }
  }
}
.tips-title{
  display: flex;
  justify-content: space-between;
  margin: 2rem 1rem 0 2rem;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: .005em;
  line-height: 26px;
  cursor: pointer;
}
.tips-add-box{
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 2rem 12px 0px 12px;
}
.tips-add--inner-box{
  display: flex;
  align-items: center;
}
.tipsListBox{
  padding: 0rem 1rem 1rem 1rem;
  max-width: 45rem;
  ul{
    box-sizing: border-box;
    padding: 6px 12px;
    display: flex;
    flex-direction: column;
    gap:15px;
    flex-wrap: wrap;
    li{
      cursor: pointer;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 18.2px;
      //color: #056de8;
      border-radius: 8px;
      background-color: rgba(5,109,232,.15);
      margin-left:1rem;
      list-style: none;
      width: 100%;
      .tipsListInnerBox{
        min-height: 5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6.5px 12px;
        p{
          margin-bottom: 0;
        }
        .left-tips-box{
          padding: 0.5rem;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
      .tipsListDetailBox{
        width: 100%;
        padding: 12px;
        background: #fafafa;
        p{
          color: rgba(0, 0, 0, 0.79);
          overflow-wrap: break-word;
        }
      }
    }
    li .active{
      background: #056de8;
      color: #fff;
    }
    .tips-note-title{
      font-size:16px;
      color: #056de8;
      font-weight: bold;
    }
    .tips-note{
      color: #056de8a1;
      font-size:13px;
      max-width: 12rem;
      margin-top: 10px;
    }
    .tips-look-btn{
      color: #056de8;
      width: 100px;
    }
  }
}
.md-textarea{
  overflow: auto !important;
}
</style>
