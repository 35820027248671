<template>
  <div class="content">
    <!--内容部分s-->
    <!--办公内容s-->
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25" v-for="(item,index) in allList" :key="index" @click="toDetail(item,index)">
        <base-card data-background-color="blue">
          <template slot="header">
            <md-icon>{{item.SceneEn.String | formatIconType}}</md-icon>
          </template>

          <template slot="content">
            <p class="category">{{item.CategoryCn.String}}</p>
            <h4 class="title">{{item.SceneCn.String}}</h4>
          </template>

          <template slot="footer">
            <div class="stats">
              {{item.suggest}}
            </div>
          </template>
        </base-card>
      </div>
    </div>
    <!--办公内容e-->
    <!--内容部分e-->
  </div>
</template>

<script>
import {
  BaseCard,
  StatsCard
} from "@/components";
import * as API from "@/api";

export default {
  name: 'Eduction.vue',
  components: {
    BaseCard,
  },
  data() {
    return {
      allList:[]
    };
  }, mounted(){
    this.getAllTipList()
  },
  filters: {
    // 格式化返回的图标类型
    formatIconType(value) {
      switch (value) {
        case "PopulationStrategy":
          return 'reorder'
          break;
        case "MediaStrategy":
          return 'reorder'
          break;
        case "EventPlanning":
          return 'reorder'
          break;
        case "MediaStrategy":
          return "subscriptions"
          break;
        case "PictureGenerator":
          return 'add_a_photo'
          break;
        case "AudienceStrategy":
          return 'real_estate_agent'
          break;
        case "MarketingTools":
          return 'work_outline'
          break;
        case "Translation":
          return 'translate'
          break;
        case "CopywritingAssistant":
          return 'article'
          break;
        case "DocumentAnalysis":
          return 'article'
          break;
        default:
          return 'approval'
      }
    },
  },
  methods: {
    getAllTipList() {
      var obj = {
        categoryName: 'ExpansionTools'
      }
      API.getSubTipsList(obj).then(res => {
        if (res.code == 200) {
          this.allList = res.data.list
        }
      })
    },
    // 点击跳转到内容详情页面
    toDetail(item) {
      if (item.GroupType.String == 'text') {
        this.$router.push({
          path: '/detail',
          query: {
            category: item.CategoryEn.String,
            scene: item.SceneEn.String
          }
        })
      } else {
        this.$router.push({
          path: '/imageDetail'
        })
      }
    }
  }
};
</script>

<style scoped>

</style>
