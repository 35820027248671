<template>
  <div class="content">
    <!--内容部分s-->
    <!--常用内容s-->
      <div v-for="(item,index) in cateSceneList" :key="index">
        <h5 class="title" style="padding-left:10px;">{{item|formatFromType}}</h5>
        <SceneCard :sceneType="item" :cateType="item"></SceneCard>
    </div>
    <!--常用内容e-->
  </div>
</template>

<script>
import SceneCard from '@/components/SceneCard/index';
import * as API from '@/api/index';
import {getAllTipsListNew} from "@/api/index";
import store from "@/store";

export default {
  name: 'Common.vue',
  components: {
    SceneCard
  },
  filters:{
    formatFromType(value){
      switch (value) {
        case "ImageCreation":
          return '图片创作'
          break;
        case "ExpansionTools":
          return '扩展工具'
          break;
        case "HitStrategy":
          return '投中策略'
          break;
        case "OfficeAssistant":
          return '办公助手'
          break;
        case "PreInvestmentStrategy":
          return '投前策略'
          break;
        default:
          return 'black'
      }
    },
    formatBgType(value){
      switch (value) {
        case "ExpansionTools":
          return 'blue'
          break;
        case "HitStrategy":
          return 'orange'
          break;
        case "ImageCreation":
          return 'red'
          break;
        case "PreInvestmentStrategy":
          return 'green'
          break;
        default:
          return 'black'
      }
    },
    // 格式化返回的图标类型
    formatIconType(value){
      switch (value) {
        case "PopulationStrategy":
          return 'reorder'
          break;
        case "MediaStrategy":
          return 'reorder'
          break;
        case "EventPlanning":
          return 'reorder'
          break;
        case "MediaStrategy":
          return "subscriptions"
          break;
        case "PictureGenerator":
          return 'add_a_photo'
          break;
        case "AudienceStrategy":
          return 'real_estate_agent'
          break;
        case "MarketingTools":
          return 'work_outline'
          break;
        case "Translation":
          return 'translate'
          break;
        case "CopywritingAssistant":
          return 'article'
          break;
        case "DocumentAnalysis":
          return 'article'
          break;
        default:
          return 'approval'
      }
    },
  },
  data() {
    return {
      allList:[],
      cateSceneList: []
    };
  },
  mounted(){
    // 在Vue组件中
    this.getAllTipList()
  },
  methods: {
    getAllTipList() {
      API.getAllTipsListNew().then(res => {
        if (res.code == 200) {
          this.allList = res.data.list
          var arr = []
          this.allList.forEach(item=>{
            arr.push(item.CategoryEn.String)
            this.cateSceneList = [...new Set(arr)]
          })
        }
      })
    },
    // 点击跳转到内容详情页面
    toDetail(item) {
      if (item.GroupType.String == 'text') {
        this.$router.push({
          path: '/detail',
          query: {
            category: item.CategoryEn.String,
            scene: item.SceneEn.String
          }
        })
      } else {
        this.$router.push({
          path: '/imageDetail'
        })
      }
    }
  }
};
</script>
