<template>
  <md-toolbar md-elevation="0" class="md-transparent">
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <h3 class="md-title" style="cursor: pointer" @click="back">{{$route.name | showRoute}}</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-simple md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <div class="md-collapse">
<!--          <div class="md-autocomplete">-->
<!--            <md-autocomplete-->
<!--              class="search"-->
<!--              v-model="selectedEmployee"-->
<!--              :md-options="employees"-->
<!--            >-->
<!--              <label>Search...</label>-->
<!--            </md-autocomplete>-->
<!--          </div>-->
          <md-list>
            <md-list-item href="#/">
              <i class="material-icons">dashboard</i>
              <p class="hidden-lg hidden-md">Dashboard</p>
            </md-list-item>

            <!-- <md-list-item href="#/notifications" class="dropdown">
              <drop-down>
                <a slot="title" class="dropdown-toggle" data-toggle="dropdown">
                  <i class="material-icons">notifications</i>
                  <span class="notification">5</span>
                  <p class="hidden-lg hidden-md">Notifications</p>
                </a>
                <ul class="dropdown-menu dropdown-menu-right">
                  <li><a href="#">Mike John responded to your email</a></li>
                  <li><a href="#">You have 5 new tasks</a></li>
                  <li><a href="#">You're now friend with Andrew</a></li>
                  <li><a href="#">Another Notification</a></li>
                  <li><a href="#">Another One</a></li>
                </ul>
              </drop-down>
            </md-list-item> -->

<!--            <li class="md-list-item">-->
<!--              <a-->
<!--                href="#/notifications"-->
<!--                class="md-list-item-router md-list-item-container md-button-clean dropdown"-->
<!--              >-->
<!--                <div class="md-list-item-content">-->
<!--                  <drop-down>-->
<!--                    <md-button-->
<!--                      slot="title"-->
<!--                      class="md-button md-just-icon md-simple"-->
<!--                      data-toggle="dropdown"-->
<!--                    >-->
<!--                      <md-icon>notifications</md-icon>-->
<!--                      <span class="notification">5</span>-->
<!--                      <p class="hidden-lg hidden-md">Notifications</p>-->
<!--                    </md-button>-->
<!--                    <ul class="dropdown-menu dropdown-menu-right">-->
<!--                      <li><a href="#">Mike John responded to your email</a></li>-->
<!--                      <li><a href="#">You have 5 new tasks</a></li>-->
<!--                      <li><a href="#">You're now friend with Andrew</a></li>-->
<!--                      <li><a href="#">Another Notification</a></li>-->
<!--                      <li><a href="#">Another One</a></li>-->
<!--                    </ul>-->
<!--                  </drop-down>-->
<!--                </div>-->
<!--              </a>-->
<!--            </li>-->

            <md-list-item href="#/login">
              <i class="material-icons">person</i>
              <p class="hidden-lg hidden-md">Profile</p>
            </md-list-item>
          </md-list>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
export default {
  data() {
    return {
      selectedEmployee: null,
      employees: [
        "全部",
        "常用",
        "办公",
        "运营",
        "教育",
        "工具",
      ],
      showBack: false,
      routeName: null,
    };
  },
  filters:{
    showRoute(val){
      var arr = ['DashboardLayout','Dashboard','Common','User Profile','Table List','Typography','Icons','Maps','Notifications','Upgrade to PRO','CommonUse','OfficialBusiness','Operate','RedOperate','Program','Education','Life']
      if (arr.indexOf(val) > 0) {
        return 'EMARK AI'
      } else {
        return '<<  返回上一级'
      }
    }
  },
  methods: {
    isShowRoute(val){
      var arr = ['Dashboard','Common','User Profile','Table List','Typography','Icons','Maps','Notifications','Upgrade to PRO','CommonUse','OfficialBusiness','Operate','Program','Education','Life']
      if (arr.findIndex(val) > 0) {
        return false
      } else {
        return true
      }
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    back(){
      this.$router.go(-1)
    },
  },

};
</script>

<style lang="css"></style>
