<template>
  <div class="content">
    <div>
      <p class="md-headline">选择大模型或者服务平台</p>
    </div>
    <div class="md-layout" >
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-20" v-for="(item,index) in chatModalList"
           :key="index" @click="chooseItem(item,index)">
        <div class="modal-box md-elevation-4" :class="item.isChoose?'activeChoose':''">
          <img :src="item.img" class="modal-img"/>
          <span class="md-title">{{ item.name }}</span>
          <md-checkbox v-model="item.isChoose" class="md-primary md-checkbox"></md-checkbox>
        </div>
      </div>
    </div>
    <div class="chooseBtnBox">
      <span></span>
      <md-button class="md-primary" @click="notifyVue('top', 'center')">确认选择</md-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Program.vue',
  components: {},
  data() {
    return {
      chatModalList: [{
        isChoose: false,
        name: '讯飞星火认知大模型',
        img: require('../assets/img/platform/xunfei_xinghuo.png')
      }, {
        isChoose: false,
        name: 'OpenAI ChatGPT',
        img: require('../assets/img/platform/chat_gpt.png')
      }, {
        isChoose: false,
        name: 'Baidu 文心一言',
        img: require('../assets/img/platform/baidu_wenxin.png')
      }, {
        isChoose: false,
        name: '阿里云 通义千问',
        img: require('../assets/img/platform/aliyun.png')
      }, {
        isChoose: false,
        name: '腾讯 混元大模型',
        img: require('../assets/img/platform/texnxun.png')
      },{
        isChoose: false,
        name: 'OpenAI Dall.e.3',
        img: require('../assets/img/platform/open_ai.jpg')
      }, {
        isChoose: false,
        name: 'MidJourney',
        img: require('../assets/img/platform/midJourney.png')
      }],
      imgModalList: [],
      type: ["", "info", "success", "warning", "danger"],
    }
  },
  methods: {
    // 点击选择元素
    chooseItem(item, index) {
      item.isChoose = !item.isChoose
      this.$forceUpdate()
    },
    notifyVue(verticalAlign, horizontalAlign) {
      var color = Math.floor(Math.random() * 4 + 1);
      this.$notify({
        message:
            "切换模型成功！",
        icon: "add_alert",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: 'success',
      });
      this.chatModalList.forEach(item=>{
        item.isChoose = false
      })
      this.$forceUpdate()
    },
  }
}
</script>

<style lang="scss" scoped>
.md-headline{
  padding: 1.2rem;
}
.md-checkbox{
  padding:0 !important;
  margin: 0 !important;
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.modal-box {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 10rem;
  background: #fff;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  margin-bottom: 2rem;

  .modal-img {
    width: 50px;
    height: 50px;
  }
}
.activeChoose{
  border:2px solid #800080FF;
}
.chooseBtnBox{
  padding: 1.2rem;
  display: flex;
  justify-content: space-between;
  font-size: 20px !important;
}
</style>
