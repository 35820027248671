<template>
  <div class="darw-out-box">
    <div class="darw-out-img-box">
      <span class="downBtn"  @click="downLoad">下载图片</span>
      <img :src="imgSrcNew" class="draw-all-img"/>
      <div class="draw-out-box-close">
        <span @click="closeDrawBox">
          <md-icon class="edit">close</md-icon>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import * as API from '@/api/index';
export default {
  name: "image",
  props: {
    imgSrcNew: [String,Array],
  },
  data(){
    return{
    }
  },
  methods: {
    // 下载图片
    downLoad(){
      window.open(this.imgSrcNew)
    },
    closeDrawBox(){
      this.$emit('closeDrawNew',false)
    }
  },
}
</script>

<style scoped lang="scss">
.darw-out-box{
  position: fixed;
  inset: 0px;
  z-index: 9999;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: rgb(0, 0, 0);
  .darw-out-img-box{
    background-color: rgba(255, 255, 255, 0.2);
    position: relative;
    width: fit-content;
    height: auto;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    .draw-all-img{
      height: auto;
      max-height: 100vh;
      transition: transform 0.3s ease 0s;
    }
    .draw-one-box-img{
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      flex-wrap: wrap;
    }
    .draw-one-img{
      width: 50%;
      height: 50%;
      cursor: pointer;
      transition: all 0.3s ease-in-out 0s;
      box-sizing: border-box;
    }
    .draw-one-img:hover{
      background-color: rgba(0, 0, 0, 0.3);
      border: 10px solid rgb(255, 255, 255);
      cursor: pointer;
    }
    .draw-out-box-close{
      position: absolute;
      bottom: 5%;
      background: rgba(255, 255, 255, 0.6);
      color: #0c0a06;
      //color: rgba(255, 255, 255, 0.2);
      //background: rgb(218 218 218 / 60%);
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;
      span{
        //user-select: none;
        //width: 1em;
        //height: 1em;
        //display: inline-block;
        //fill: currentcolor;
        //flex-shrink: 0;
        //font-size: 40px;
        //color: rgba(255, 255, 255, 0.2);
        font-weight: bold;
        transition: all 0.3s ease-in-out 0s;
      }
    }
    .draw-out-box-close:hover{
      background: rgba(255, 255, 255, 1);
      color: #fff;
    }
  }
}
.downBtn{
  padding: 5px 10px;
  background: rgb(59, 178, 255);
  color: #fff;
  position: absolute;
  right: 20px;
  top: 20px;
  border-radius: 20px;
  cursor: pointer;
}
.spinBox{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: rgb(0,0,0,.5);
  justify-content: center;
}
</style>
