import baseImg from '@/assets/img/lostImg.png';
let timer = null;
let observer = new IntersectionObserver(entries => {
    entries.forEach(entry=>{
        if(entry.isIntersecting || entry.intersectionRatio>0){
            !entry.target.isLoaded && showImage(entry.target,entry.target.data_src);
        }
    });
});
function showImage(el,imgSrc){
    const img = new Image();
    img.src = imgSrc;
    img.onload = () => {
        el.src = imgSrc;
        el.isLoaded = true;
    };
}
export default {
    inserted(el, binding, vnode, oldVnode) {
        clearTimeout(timer);
        el.src = '图片加载失败';
        el.data_src = binding.value;
        observer.observe(el);

        // 防抖
        const vm = vnode.context;
        timer = setTimeout(()=>{
            vm.$on('hook:beforeDetory',()=>{
                observer.disconnect();
            });
        });
    },
    update(el,binding){
        el.isLoaded = false;
        el.data_src = binding.value;
    }
};



// const lazyLoad = {
//     // mounted 在绑定元素的父组件
//     // 及他自己的所有子节点都挂载完成后调用
//     mounted(el, binding) {
//         // 如果有需要可以先设置src为 loading 图
//         // el.setAttribute('src', 'loading 图的路径');
//         const options = {
//             rootMargin: '0px',
//             threshold: 0.1,
//         };
//         const observer = new IntersectionObserver((entries) => {
//             entries.forEach((entry) => {
//                 if (entry.isIntersecting) {
//                     // binding 是一个对象，binding.value 是传递给指令的值
//                     el.setAttribute('src', binding.value);
//                     observer.unobserve(el);
//                 }
//             });
//         }, options);
//         observer.observe(el);
//     },
// };

// export default lazyLoad;
