<template>
  <div class="all">
    <div class="allIn" :style="isMicroMessengerBrowser?'width:100%':'width: 85%;'">
      <h1 v-if="!isMicroMessengerBrowser">
        <div>EMARK &nbsp; AI</div>
        <br>
        <div>智能营销，效率飞跃。</div>
      </h1>
      <div class="btnSubmit" v-if="!isMicroMessengerBrowser"><a
          href="https://open.weixin.qq.com/connect/qrconnect?appid=wx4b522f57190ff003&redirect_uri=https://e-ai.emarkdigital.tech/dev/open/wx/webAuthLogin&response_type=code&scope=snsapi_login&state=vY4f7lqc9xfHvH#wechat_redirect">Let's
        Go</a></div>
      <div class="mobileBox" v-if="isMicroMessengerBrowser">
        <img src="../assets/img/logo.png" class="mobileLogo"/>
        <a class="mobileBtn"
           href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa2b5a2363cc7d279&redirect_uri=https://e-ai.emarkdigital.tech/dev/open/wx/authLogin&response_type=code&scope=snsapi_userinfo&state=vY4f7lqc9xfHvH#wechat_redirect">Learn
          More AI</a>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";

let Base64 = require('js-base64').Base64;

export default {
  name: "login",
  data() {
    return {
      isMicroMessengerBrowser: false // true 微信 false pc
    }
  },
  methods: {
    isMicroMessengerBrowserFunc() {
      var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
      var isMicroMessenger = userAgent.indexOf("MicroMessenger") > -1; //判断是否微信浏览器
      var isWxWork = userAgent.indexOf("wxwork") > -1; //判断是否企业微信浏览器
      if (isMicroMessenger && !isWxWork) {
        this.isMicroMessengerBrowser = true
        console.log(" is wechat browser : ", this.isMicroMessengerBrowser)
        //alert(" is wechat browser : ",this.isMicroMessengerBrowser)
        return true;
      } else {
        this.isMicroMessengerBrowser = false
        console.log(" is wechat browser : ", this.isMicroMessengerBrowser)
        return false;
      }
      ;
    }
  },
  mounted() {
    localStorage.setItem('url', this.$route.query)
    if (this.$route.query.access_token) {
      var arr = this.$route.query.access_token.split('.')
      var users = Base64.decode(arr[1])
      const userName = JSON.parse(users).user // unionid
      // const nickname = JSON.parse(users).user
      const token = this.$route.query.access_token
      store.commit('SET_USERNAME', userName)
      // store.commit('SET_WECHATNICKNAME',nickname)
      store.commit('SET_TOKEN', token)
      if (this.$route.query.wechat_nickname) {
        const nickname = this.$route.query.wechat_nickname
        store.commit('SET_WECHATNICKNAME', nickname)
        this.$router.push('/dashboard')
      }
    }
    this.isMicroMessengerBrowserFunc()
  }
  /*mounted() {
    localStorage.setItem('url', this.$route.query)
    var nickname = 'Dylan'
    var userName = 'oLDeNxCFESIY13XhPLYWecOW60L4'
    var token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MjEzOTk0MDYsInBhc3N3ZCI6Im82WnB5NlY2bjAtYUZsMTNDWmdVRUN2bXlabEkiLCJ1c2VyIjoib0xEZU54QlBnaXZnS3hNSXoxMTMxRlhWUFdyWSJ9.ieg30pcqIo2webHbSYSDvI4WtsdAPc0ZEgnzBmoJDlE'
    store.commit('SET_USERNAME', userName)
    localStorage.setItem("userName", userName);
    store.commit('SET_TOKEN', token)
    store.commit('SET_WECHATNICKNAME', nickname)
    this.$router.push('/dashboard')
    this.isMicroMessengerBrowserFunc()
  }*/

}
</script>

<style scoped>
/*@import url('https://fonts.googleapis.com/css?family=Alegreya+Sans:400,300,500,700,900');*/

.all {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font: 900 20px/1 Alegreya Sans, sans-serif !important;
  font-size: 20px;
  font-size: calc(20px + 2vw);
  position: relative;
  z-index: 0 !important;
}

.all {
  perspective: 300px;
  background: #000000;
  background-size: cover;
}

.allIn {
  font: 900 20px/1 Alegreya Sans, sans-serif !important;
  font-size: calc(20px + 2vw) !important;
  transform-style: preserve-3d;
}

h1:hover {
  transform: rotateY(5deg);
  transition: transform 1s;
}

h1:hover a {
  transform: translateZ(0);
}

h1 {
  font: 900 calc(50px + 2vw)/1 Alegreya Sans, sans-serif !important;
  transform: rotateY(0deg);
  transform-style: preserve-3d;
  color: whitesmoke;
  transition: transform 1s;
}

h1 a {
  display: inline-block;
  transform: translateZ(30px);
  transition: transform 1s;
  color: dodgerblue !important;
}

.btnSubmit {
  cursor: pointer;
  position: absolute;
  z-index: 11111;
  bottom: -20%;
  color: #fff !important;
  right: 8%;
  font-size: 2rem;
  background: dodgerblue;
  padding: 0.6rem 0.8rem;
  border-radius: 4px;
  overflow: hidden;
  transition: all 0.15s ease-in;
}

.btnSubmit a {
  color: #fff !important;
  text-decoration: none;
}

.btnSubmit:before {
  content: ' ';
  position: absolute;
  background: #fff;
  width: 25px;
  height: 50px;
  top: 0;
  left: -45px;
  opacity: 0.3;
  transition: all 0.25s ease-out;
  transform: skewX(-25deg);
}

.btnSubmit:hover {
  transform: scale(1.05);
  background: #1e90ff;
}

.btnSubmit:hover:before {
  width: 45px;
  left: 205px;
}

.mobileBox {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  position: relative;
  z-index: 1111;
}

.mobileLogo {
  width: 70%;
  position: absolute;
  top: 24%;
  left: 50%;
  transform: translate(-50%);
}

.mobileBtn {
  width: 70%;
  height: 7%;
  background: #000000;
  position: absolute;
  top: 68%;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  text-decoration: none;
  color: #fff !important;
  font-size: 2rem;
}
</style>
