<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>

    <side-bar
      :sidebar-item-color="sidebarBackground"
      :sidebar-background-image="sidebarBackgroundImage"
    >
      <mobile-menu slot="content"></mobile-menu>
      <sidebar-link to="/dashboard">
        <md-icon>dashboard</md-icon>
        <p>全部</p>
      </sidebar-link>
      <sidebar-link to="/commonUse">
        <md-icon>store</md-icon>
        <p>常用</p>
        <div class="hotLabel">Hot</div>
      </sidebar-link>
      <sidebar-link to="/officialBusiness">
        <md-icon>work</md-icon>
        <p>投前策略</p>
      </sidebar-link>
      <sidebar-link to="/operate">
        <md-icon>library_books</md-icon>
        <p>投中策略</p>
      </sidebar-link>
      <sidebar-link to="/education">
        <md-icon>school</md-icon>
        <p>拓展工具</p>
      </sidebar-link>
      <sidebar-link to="/life">
        <md-icon>support</md-icon>
        <p>办公助手</p>
      </sidebar-link>
      <sidebar-link to="/program">
        <md-icon>fiber_new</md-icon>
        <p>大模型设计</p>
      </sidebar-link>
      <sidebar-link to="/redOperate">
        <md-icon>fiber_new</md-icon>
        <p>小红书运营助手</p>
      </sidebar-link>
      <sidebar-link to="/upgrade" class="active-pro">
        <md-icon>feedback</md-icon>
        <p>建议和反馈</p>
      </sidebar-link>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <fixed-plugin
        :color.sync="sidebarBackground"
        :image.sync="sidebarBackgroundImage"
      >
      </fixed-plugin>

      <dashboard-content> </dashboard-content>

<!--      <content-footer v-if="!$route.meta.hideFooter"></content-footer>-->
    </div>
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "@/pages/Layout/MobileMenu.vue";
import FixedPlugin from "./Extra/FixedPlugin.vue";

export default {
  components: {
    TopNavbar,
    DashboardContent,
    // ContentFooter,
    MobileMenu,
    FixedPlugin,
  },
  data() {
    return {
      sidebarBackground: "green",
      sidebarBackgroundImage: require("@/assets/img/sidebar-2.jpg"),
    };
  },
};
</script>
