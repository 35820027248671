<template>
  <footer class="footer">
    <div class="container">
      <nav>
        <ul>
          <li>
            <a href="#">AI开放平台</a>
          </li>
          <li>
            <a href="#"> About Us </a>
          </li>
          <li>
            <a href="#"> Licenses </a>
          </li>
        </ul>
      </nav>
      <div class="copyright text-center">
        手机小程序使用
<!--        &copy; {{ new Date().getFullYear() }}-->
<!--        <a href="https://www.creative-tim.com/?ref=mdf-vuejs" target="_blank"-->
<!--          >Creative Tim</a-->
<!--        >, made with <i class="fa fa-heart heart"></i> for a better web-->
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style></style>
