import Vue from "vue";

//import ElementUI from 'element-ui';

import App from "./App";
import router from "./routes/routes";

// Plugins
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import Notifications from "./components/NotificationPlugin";
import MaterialDashboard from "./material-dashboard";
import Chartist from "chartist";
import axios from 'axios';
import store from './store';
import imgLazy from './directives/index';
import './assets/scss/font.css';// 导入字体

Vue.directive('imgLazy', imgLazy);
Vue.prototype.$http = axios;
Vue.prototype.$Chartist = Chartist;
Vue.use(MaterialDashboard);
Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
Vue.use(Notifications);
Vue.config.productionTip = false;  //设置在控制台环境进行代码提示作用

//Vue.use(ElementUI);

new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
  store,
  data: {
    Chartist: Chartist,
  },
});
