<template>
  <div class="content">

    <div class="md-layout">
      <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100 left-detail"
      >
        <div class="md-layout-item md-size-100">
          <div class="chat-container md-scrollbar md-theme-default" ref="mainBox">
            <!--聊天提示词部分s-->
            <div ref="chatScroll">
              <div>
                <p>参考下方示例输入描述</p>
                <div>
                  <div class="descBox">
                    <div class="descBoxItem">
                      <span class="descBoxItemActive">主体物</span>
                      <span>放学回家的女孩</span>
                    </div>
                    <span class="descBoxItemAdd">+</span>
                    <div class="descBoxItem">
                      <span class="descBoxItemActive">细节</span>
                      <span>开心</span>
                    </div>
                    <span class="descBoxItemAdd">+</span>
                    <div class="descBoxItem">
                      <span class="descBoxItemActive">提示词</span>
                      <span>精致的五官，大师作品，最好画质</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--聊天提示词部分e-->
            <!--聊天内容部分s-->
            <div style="transform: rotate(180deg)" ref="scrollBox">
              <div id="hiddenBox" style="width: 50px;height: 1px;background: transparent"></div>
              <div v-if="isLoading" style="transform: rotate(180deg)">
                <div style="display: flex;justify-content: flex-end;width: 100%;" v-if="isLoading">
                  <div class="chat_from md-elevation-4">
                    <div class="questionIfo" v-html="lastQuestion"></div>
                  </div>
                </div>
                <div class="chat_to thinking-box" v-if="isLoading" >
                  <span>正在思考中 <a style="color: red;">(大概需要40s)</a></span>
                  <span>请耐心等待，不要关闭当前页面，否则可能会生成中断。</span>
                  <div class="parentBox" >
                    <div class="contantBox"></div>
                  </div>
                </div>
              </div>
              <div style="transform: rotate(180deg)" v-for="(item,index) in historyChat" :key="index">
                <div class="dateTime" v-if="item.Prompt.String">{{ item.AskedTime.Time | dateForm }}</div>
                <div style="display: flex;justify-content: flex-end;width: 100%;">
                  <div class="chat_from md-elevation-4" v-if="item.Prompt.String">
                    <div class="questionIfo" v-html="item.Prompt.String"></div>
                    <div class="chat_from_copy-box md-elevation-1 md-raised">
                      <span @click="copyText(item.Prompt.String)">
                        <md-icon class="content_copy ">content_copy</md-icon>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="dateTime">{{ item.RetrievedTime.Time | dateForm }}</div>
                <div class="chat_to md-elevation-4">
                  <div>
                    <img :src="item.MjAnswer.String" class="draw-img-box"
                         @click="openImgInThisPage(item.MjAnswer.String,item.MsgHash.String,item.TriggerId.Int64,item.MsgId.Int64,item.SelectIndex.Int64)"/>
                  </div>
                </div>
              </div>
            </div>
            <!--聊天内容部分e-->
            <div id="msg_end" style="height:0px;"></div>
          </div>
        </div>
        <div class="send-box">
          <div class="action-box" style="align-items: center;">
            <!--清除历史s-->
            <div class="clear-history-box" @click="removeHistory">
              <span class="material-icons">
                restart_alt
              </span>
              <span>清除历史</span>
            </div>
            <!--清除历史e-->
            <!--提示词s-->
            <div class="info-box" @click="infoBoxClick">
              <span class="material-icons md-raised">
              lightbulb
              </span>
              <span>提示词</span>
            </div>
            <!--提示词e-->
          </div>

          <!--生成图片比例s-->
          <div class="imgSizeBox md-elevation-5" v-if="description">
            <div class="imgSizeBoxTitle">
              <span>生成图片比例</span>
            </div>
            <div class="imgSizeBoxIn">
              <div  @click="chooseImgSize(item)" v-for="(item,index) in imgSizeList" :key="index" class="imgSizeBoxInBox" :class="item.isChooose?'active':''">
                <div :class="item.className" class="imgSizeBoxInner"></div>
                <span>{{item.size}}</span>
              </div>
            </div>
          </div>
          <!--生成图片比例e-->

          <md-field style="margin: 0 !important;background: #eee !important;">
            <md-textarea v-model="description" :md-autogrow="true" class="chat-text-input"
                         placeholder="请输入新的主题关键词">
              <textarea></textarea>
            </md-textarea>
            <md-button class="md-fab md-success" @click="sendContent">
              <md-icon class="send">send</md-icon>
            </md-button>
          </md-field>
        </div>
      </div>
      <md-snackbar md-position="center" :md-duration="4000" :md-active.sync="showSnackbar" md-persistent>
        <span>复制成功!</span>
      </md-snackbar>

      <!--提示词弹窗s-->
      <md-drawer class="md-right" :md-active.sync="showDialog" v-if="showDialog">
        <div class="tips-title">
          <span>推荐提示词</span>
          <div @click="showDialog = false">
            <span class="material-icons">
            close
            </span>
          </div>
        </div>
        <div class="tipsListBox md-scrollbar">
          <div v-for="(item,index) in tipsList" :key="index">
            <p class="tips-top-title">{{ item.SceneCn.String }}</p>
            <div class="tipsListInnerBox">
              <div v-for="(itemIn,indexIn) in item.tipList" :key="indexIn" class="tips-look-btn"
                   :class="itemIn.activeItem?'active-tips-look-btn':''" @click="toChooseActiveTip(itemIn,indexIn)">
                {{ itemIn.cnName }}
              </div>
            </div>
          </div>
        </div>
      </md-drawer>
      <!--提示词弹窗e-->

      <!--点击预览单张图片s-->
      <ImagePreview :imgSrc="activeImg" :activeMsgHash="activeMsgHash" :activeTriggerId="activeTriggerId"
                    :activeMsgId="activeMsgId" v-if="showDetailImg" @closeDraw="closeDraw"></ImagePreview>
      <ImagePreviewNew :imgSrcNew="activeImgNew" v-if="showDetailImgNew" @closeDrawNew="closeDrawNew"></ImagePreviewNew>
      <!--点击预览单张图片e-->
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import EasyTyper from 'easy-typer-js';
import * as API from '@/api/index';
import ImagePreview from '../components/ImagePreview/index';
import ImagePreviewNew from '../components/ImagePreviewNew/index';
import imgLazy from '@/directives/index';
import store from "@/store";
import { EventSourcePolyfill } from 'event-source-polyfill';
import { driver } from 'driver.js';
import "driver.js/dist/driver.css";


import {
  clearChatHistory, clearImgChatHistory,
  getAllImgMjPromptHotKeys,
  getAllTipsList,
  getImgByTriggerId, getImgCategory,
  getImgHistoryByName,
  openChat,
  openImgChat
} from "@/api/index";

export default {
  name: "detail",
  components: {
    ImagePreview,
    ImagePreviewNew
  },
  directives: {
    imgLazy: imgLazy,
  },
  filters: {
    // 格式化时间
    dateForm: function (el) {
      // moment.locale('zh-cn');
      // var fromDate = moment(el).format('YYYY-MM-DD HH:mm:ss')
      // const onHourBefore = moment.utc().subtract(24, "hours").format("YYYY,MM-DD HH:mm:ss");
      // const onHourNow = moment.utc().subtracat("YYYY,MM-DD HH:mm:ss");
      // const startWeek = moment().weekday(1);
      // const endWeek = moment().weekday(7);
      // if (moment(el).isBetween(startWeek, endWeek)) {
      //   // 表示时间在24小时内
      //   return moment().format('dddd HH:mm')
      // } else {
        return moment(el).format('YYYY-MM-DD HH:mm');
      // }
    }
  },
  data() {
    return {
      description: '',
      chooseItem: {},
      exampleList: [
        {
          name: '实例1',
          desc: '地球，外星人，哈哈，蹦跳，欢乐，漫天的糖果，小朋友，满天飞',
        },
        {
          name: '实例2',
          desc: '请把以下要点，扩展成一篇文章\n' +
              '\n' +
              '      1.关于国家“一带一路”重要战略的深入研究，关于“一带一路”沿线国家和地区经济和国际关系情况对高校志愿服务的影响；\n' +
              '      2.“一带一路”背景下，高校志愿服务国际化发展面临的困境、挑战和机遇；\n' +
              '      3.高校志愿服务国际化新模式的构建和发展；\n' +
              '      4.国际化志愿服务发展对青年志愿者成长发展的利好影响',
        },
        {
          name: '实例3',
          desc: '她，他，教室，黑板，作业本，不敢说话。十年后，街边，路过，说你好，微笑',
        }
      ],
      showContent: false,
      chatDetail: '',
      isLoading: false,
      showChatRespond: false,
      obj: { // 打字机效果的基础参数
        output: '',
        isEnd: false,
        speed: 80,
        singleBack: false,
        sleep: 0,
        type: 'normal',
        backSpeed: 40,
        sentencePause: false
      },
      historyChat: [],// 历史记录的列表
      showSnackbar: false,
      position: 'center',
      duration: 4000,
      isInfinity: false,
      showDialog: false,
      tipsList: [],
      lastQuestion: '',
      showDetailImg: false,// 选择指定索引的图片
      showDetailImgNew: false,// 选择单张图片
      activeImg: null,
      activeImgNew: null,
      activeMsgHash: null,
      activeTriggerId: null,
      activeMsgId: null,
      chooseActiveTip: [],
      activeImgSize: null, // 默认的图片尺寸
      imgSizeList: [{
        isChooose:true,
        className:'imgSizeBoxIn-one',
        size:'1:1',
        air: 'ar 1:1'
      },{
        isChooose:false,
        className:'imgSizeBoxIn-two',
        size:'4:3',
        air: 'ar 4:3',
      },{
        isChooose:false,
        className:'imgSizeBoxIn-three',
        size:'16:9',
        air: 'ar 16:9',
      },{
        isChooose:false,
        className:'imgSizeBoxIn-four',
        size:'9:16',
        air: 'ar 9:16',
      }], // 图片尺寸
      amount: -1,//图片返回的进度
    }
  },
  beforeDestroy() {
  },
  watch: {
    chooseActiveTip: {
      handler(newVal, oldVal) {
        var arr = this.description ? this.description.split(',') : this.description
        if (arr && arr.length > 0) {
          var difArr = arr.filter((item1) => !oldVal.some((item2) => item1 === item2))
          var newArr = new Set([...difArr, ...newVal])
          this.description = [...newArr].toString()
        }
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    // 点击清除历史
    removeHistory(){
      var obj = {
        userName: store.state.userName,
        category: this.$route.query.category,
        scene: this.$route.query.scene
      }
      API.clearImgChatHistory(obj).then(res=>{
        if(res.code == 200){
          this.historyChat = []
        }
      }).catch(err=>{
        alert(err)
      })
    },
    // 点击选择图片的尺寸
    chooseImgSize(item){
      this.imgSizeList.forEach(item=>{
        item.isChooose = false
      })
      item.isChooose = !item.isChooose
      this.activeImgSize = item.air
    },
    // 打开提示词弹窗
    infoBoxClick() {
      this.tipsList.forEach(list => {
        list.tipList.forEach(item => {
          item.activeItem = this.description.includes(item.cnName) ? this.description.includes(item.cnName) : ''
        })
      })
      this.showDialog = true
    },
    // 点击选择高亮的提示词
    toChooseActiveTip(item, index) {
      item.activeItem = !item.activeItem
      if (item.activeItem) {
        this.description = this.description + item.cnName + ','
      } else {
        var reg = new RegExp(item.cnName, "g")
        var reg1 = new RegExp(item.cnName + ',', "g")
        this.description = this.description.replace(reg1, "");
        this.description = this.description.replace(reg, "");
      }
      this.$forceUpdate()
    },
    toLookMoreTip() {
    },
    // 新增提示词
    addPrompt() {

    },
    // 图片预览选择的回退
    closeDraw(val) {
      this.activeImg = null
      this.showDetailImg = val
      this.getImgHistory()
    },
    closeDrawNew(val){
      this.activeImgNew = null
      this.showDetailImgNew = val
      this.getImgHistory()
    },
    // 点击当前页打开图片
    openImgInThisPage(val, hash, triggerId, id,SelectIndex) {
      if(SelectIndex == 0){
        this.activeImg = val
        this.activeMsgHash = hash
        this.activeTriggerId = triggerId
        this.activeMsgId = id
        this.showDetailImg = true
      }else{
        this.activeImgNew = val
        this.showDetailImgNew = true
      }
    },
    // 点击复制
    copyText(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.showSnackbar = true
      })
    },
    // 点击实例
    toSend(item, index) {
      this.chooseItem = item
      this.description = item.desc
    },
    // 点击生成
    sendContent() {
      this.showContent = true
      var now = moment().valueOf();
      var obj = {
        prompt: this.activeImgSize ? this.description + ', --'+this.activeImgSize : this.description,
        userName: store.state.userName,
      }
      if((this.description&&this.description.trim() == '')||((this.description + ', --'+this.activeImgSize).trim() == '')){
        return
      }
      this.isLoading = true
      var str = this.description
      this.lastQuestion = str
      this.description = null
      this.showChatRespond = true
      this.$nextTick(() => {
        setTimeout(()=>{
          var element = document.getElementById("hiddenBox");
          element.scrollIntoView(true)
        })
      })
      API.openImgChat(obj).then(res => {
        if (res.message == 'success') {
          var trigger_id = res.trigger_id
          var imgES = new EventSourcePolyfill(`https://e-ai.emarkdigital.tech/dev/mjh-api/getMjQuestionsByTaskType?userName=${store.state.userName}&triggerId=${trigger_id}&taskType=generate`,
              {
                headers: {
                  'Content-Type': 'text/event-stream',
                  'Cache-Control': 'no-cache',
                  'Connection': 'keep-alive',
                  'X-Token': `${store.state.token}`
                }
              })
          imgES.addEventListener('open',()=>{
            console.log("已开启。。。");
          })
          imgES.addEventListener('message',(e)=>{
            var arr = JSON.parse(e.data)
            if(arr.code == 200){
              this.amount = arr.data.Progress.String
              this.$nextTick(() => {
                setTimeout(()=>{
                  var element = document.getElementById("hiddenBox");
                  element.scrollIntoView(true)
                  });
              })
              if(arr.data.Progress.String == 100){
                this.isLoading = false;
                this.getImgHistory()
                imgES.close();
              }
            }
          })
          imgES.addEventListener('error',(e)=>{
            imgES.close();
          })
        }
      }).catch(err => {
        alert(err)
      })
    },
    getImgHistory() {
      var obj = {
        userName: store.state.userName
      }
      API.getImgHistoryByName(obj).then(res => {
        if (res.code == 200) {
          this.historyChat = res.data.list.reverse()
          this.$nextTick(() => {
            setTimeout(()=>{
              var element = document.getElementById("hiddenBox");
              element.scrollIntoView(true)
            })
          })
          this.isLoading = false
        }
      })
    },
    // 获取当前请求的进度
    getImgDetail(trigger_id) {
      var obj = {
        userName: store.state.userName,
        triggerId: parseInt(trigger_id),
      }
      API.getImgByTriggerId(obj).then(res => {
        if (res.message == 'success') {
          if (res.data.Progress.String && res.data.Progress.String == 100) {
            this.getImgHistory()
          }
        }
      })
    },
    getAllTipList() {
      API.getAllImgMjPromptHotKeys().then(res => {
        if (res.code == 200) {
          res.data.list.forEach(item => {
            var arr = []
            var obj0 = JSON.parse(item.HotKyes.String)
            for (var key in obj0) {
              var obj = {
                "cnName": key,
                "enName": obj0[key],
                "activeItem": false
              }
              arr.push(obj)
            }
            item.tipList = arr
          })
          this.tipsList = res.data.list
        }
      }).catch(err => {
        alert(err)
      })
    },
  },
  mounted() {
    this.getImgHistory()
    this.getAllTipList()
    if(!sessionStorage.getItem('hasLoad')){
      const driverObj = new driver({
        popoverClass: 'driverjs-theme',
      })
      driverObj.highlight({
        element: '.info-box',
        popover: {
          title: '提示：',
          description: '点击可以使用系统定义的提示词模板哦~ ',
          side: "top",
          align: 'start',
          showButtons : ['next', 'previous'],
          closeBtnText: '121',
          doneBtnText: '122',
          nextBtnText : '我知道',
          prevBtnText : '124',
          allowClose: true,
          opacity: 0.75,
          onNextClick:()=>{
            driverObj.destroy()
          }
        }
      });
      sessionStorage.setItem('hasLoad',true)
    }
  }
}
</script>

<style scoped>
.md-layout-item {
  display: flex;
  flex-wrap: wrap;
}

.md-layout-item {
}

.left-detail {
  border-radius: 3px;
  padding: 0.2rem 0.2rem;
}

.left-inner-box {
  display: flex;
  align-items: center;
  height: 4.5rem;
  line-height: 4.5rem;
}

/*.left-inner-box .md-icon{*/
/*  margin: 0;*/
/*  padding: 0;*/
/*  font-size: 3rem !important;*/
/*  line-height: 3rem !important;*/
/*  width: 3rem !important;*/
/*  height: 3rem !important;*/
/*  color: #50aa54 !important;*/
/*}*/
.left-inner-box p {
  font-size: 1.5rem !important;
  line-height: 1.5rem !important;
  margin-left: 0.5rem;
  margin-bottom: 0px;
}

.left-content, .right-content {
  padding: 1rem 0;
  border-top: 1px solid #e4e4e8;
  margin-top: 1rem;
}

.left-content-textaera .md-textarea {
  width: 100% !important;
  min-height: 20rem;
  margin-top: 1rem;
  border: 1px solid #d8d8d8;
  resize: none;
  padding: 1rem;
  font-size: 1.2rem;
}

.left-inner-detail {
  width: 100%;
}

.left-content .md-btn {
  margin-right: 10px;
}

.left-content-btn {
  width: 100%;
}

.left-content-btn .md-button {
  width: 100%;
  height: 3rem;
  font-size: 1.2rem;

}

.left-content-btn span {
  margin-left: 1.5rem;
}

.right-detail {
  margin-left: 2%;
  background: #ffffff;
  border-radius: 3px;
  padding: 1.2rem;
}

.right-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.right-content img {
  width: 35%;
}

.formChat {
  background-color: #7365ff;
  border-radius: 20px;
  border-bottom-right-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #fafafa;
  display: inline-block;
  font-size: .16rem;
  letter-spacing: .6px;
  line-height: 1.75;
  max-width: 87%;
  padding: 0.13rem 0.19rem;
  position: relative;
  text-align: left;
  word-break: break-all;
}

.chat-text-input {
  width: 100% !important;
  /*-webkit-box-flex: 1 !important;*/
  -webkit-tap-highlight-color: transparent !important;
  background-color: #fff !important;
  border-radius: 1.2rem !important;
  box-shadow: 0 0.4rem 0.4rem 0 rgba(179, 178, 255, .3) !important;
  /*-webkit-flex: 1 !important;;*/
  /*flex: 1 !important;;*/
  font-size: 1.2rem;
  font-family: Arial, Helvetica, sans-serif !important;
  margin-left: 0.12rem !important;
  padding: 1rem 80px 1rem 1rem !important;
  position: relative !important;
}

.md-field:before {
  background: transparent !important;
}

.md-field:after {
  height: 0px;
}

.send-icon {
  position: absolute;
  right: 2.3rem;
  top: 20px;
  transform: translateY(-50%);
}

.chat_from {
  background-color: #7365ff;
  border-radius: 20px;
  border-bottom-right-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #fafafa;
  display: inline-block;
  letter-spacing: .6px;
  line-height: 2rem;
  max-width: 87%;
  padding: 0.5rem 0.8rem;
  position: relative;
  text-align: left;
  word-break: break-all;
  justify-content: flex-end;
  margin-top: 1rem;
}

.chat_from .chat_from_copy-box {
  display: none;
  position: absolute;
  background: white;
  padding: 5px;
  top: -30px;
  right: 10px;
  border-radius: 5px;
  font-size: 10px;
}

.chat_from:hover .chat_from_copy-box, .chat_to:hover .chat_from_copy-box {
  display: block;
}

.chat_to {
  background-color: #fff;
  border: 0.01rem solid transparent;
  border-radius: 4px 20px 20px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1e1f24;
  /*line-height: .26rem;*/
  width: fit-content;
  max-width: calc(100vw - 3rem);
  min-height: 2rem;
  line-height: 2rem;
  /*min-width: 0.72rem;*/
  padding: 0.5rem 0.8rem;
  position: relative;
  text-align: justify;
  margin-top: 1rem;
}

.chat_to .chat_from_copy-box {
  display: none;
  position: absolute;
  background: #fff;
  padding: 5px;
  top: -30px;
  right: 10px;
  border-radius: 5px;
  font-size: 10px;
  z-index: 11;
}

.example_box {
  background: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
}

.example_box_in {
  display: flex;
  width: 100%;
}

.example_box_chat {
  padding: 0.13rem 0.19rem;
  flex: 1;
  background: #f7f7fe;
  border-radius: 18px;
  cursor: pointer;
  display: block;
  height: 120px;
  margin-left: 17px;
  padding: 16px 21px 21px 20px;
  position: relative;
}

.example_box_chat_titled {
  font-family: Arial, sans-serif;
  font-weight: 600;
}

.example_box_chat_btn {
  -webkit-tap-highlight-color: transparent;
  background: #ebe9ff;
  bottom: 21px;
  color: #5240ff;
  cursor: pointer;
  font-family: PingFangSC-Semibold, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 18px;
  position: absolute;
  right: 20px;
  text-align: center;
  border-radius: 2rem;
}

.example_box_chat_btn:hover {
  background: #dcd9ff;
}

.send-box {
  position: relative;
  bottom: 10px;
  padding: 15px;
  width: 100%;
}

.send-box .md-fab .md-ripple {
  padding: 0 !important;
}

.send-box button {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-container {
  width: 100%;
  height: calc(100vh - 250px);
  overflow-y: auto;
  padding: 15px;
  box-sizing: content-box;
  margin-bottom: 10px;
}

.dateTime {
  text-align: center;
  padding-top: 1rem;
  color: #848691;
}

.action-box {
  display: flex;
  width: 100%;
  background: #eeeeee;
}

.clear-history-box {
  height:34px;
  align-items: center;
  border-radius: 0.9rem;
  min-width: 100px;
  color: #1f1f1f;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  line-height: 1;
  opacity: 1;
  cursor: pointer;
  background: #7365ff;
  color: #fff !important;
}

.info-box {
  height:34px;
  align-items: center;
  border-radius: 0.9rem;
  min-width: 100px;
  color: #1f1f1f;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  line-height: 1;
  opacity: 1;
  margin-left: 1rem;
  cursor: pointer;
  background: #fd9d18;
  color: #fff !important;
}

.clear-history-box:active {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
}

.info-box:active {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
}

.md-tabs .md-button {
  color: #495057;
}

.thinking-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
}
</style>
<style lang="scss" scoped>
.parentBox {
  height: 100%;
  margin-left: 10px;
  margin-top: 10px;

  .contantBox {
    width: 40px;
    height: 2px;
    -webkit-mask: linear-gradient(90deg, #000 70%, #0000 0) 0/10%;
    background: linear-gradient(rgb(115, 101, 255) 0 0) 0/0% no-repeat #ddd;
    animation: cartoon 1.5s infinite steps(6);
  }

  @keyframes cartoon {
    100% {
      background-size: 120%;
    }
  }
}

.descBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;

  .descBoxItemAdd {
    margin: 0px 10px;
  }

  .descBoxItem {
    padding: 6px 8px;
    border-radius: 8px;
    border: 2px solid rgb(115, 101, 255);
    margin-bottom: 10px;

    .descBoxItemActive {
      color: rgb(115, 101, 255);
      font-weight: bold;
      margin-right: 4px;
    }
  }
}

.draw-img-box {
  width: 500px;
  height: auto;
  cursor: zoom-in;
  border-radius: 20px;
  object-fit: cover;
}

.tips-title {
  display: flex;
  justify-content: space-between;
  margin: 2rem 1rem 0 22px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: .005em;
  line-height: 26px;
  cursor: pointer;
}

.tips-add-box {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 2rem 12px 0px 12px;
}

.tips-add--inner-box {
  display: flex;
  align-items: center;
}

.tipsListBox {
  max-width: 45rem;
  padding: 5px 12px 0px 12px;
}

.tips-look-btn {
  color: #056de8;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
  border-radius: 8px;
  background-color: rgba(5, 109, 232, .15);
}

.active-tips-look-btn {
  background: #056de8;
  color: #fff;
}

.tipsListInnerBox {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 6px 12px 2rem 12px;
}

.tips-top-title {
  padding: 6px 10px;
  color: #000000;
}

.md-drawer {
  width: auto !important;
}

.imgSizeBox {
  width: 300px;
  height: 140px;
  background: rgb(244, 246, 255);
  border-radius: 12px;
  padding: 15px;
  position: absolute;
  right: 20px;
  top: -120px;
  z-index: 1;
  .imgSizeBoxTitle{
    font-weight: bold;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  .active {
    background: #6669e9 !important;
    color: white !important;
      .imgSizeBoxInner{
        border: 2px solid #fff !important;
      }
  }

  .imgSizeBoxIn {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .imgSizeBoxInBox {
      width: 50px;
      height: 75%;
      background: #e0e3f8;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      position: relative;
      color: #6669e9;

      span{
        position: absolute;
        bottom: 5px;
      }
      .imgSizeBoxInner{
        border: 2px solid #6669e9;
        border-radius: 4px;
        position: absolute;
      }
      .imgSizeBoxIn-one {
        width: 25px;
        height: 25px;
        top: 15px;
      }

      .imgSizeBoxIn-two {
        width: 24px;
        height: 18px;
        top: 20px;
      }

      .imgSizeBoxIn-three {
        width: 32px;
        height: 18px;
        top: 20px;
      }

      .imgSizeBoxIn-four {
        width: 18px;
        height: 32px;
        top: 10px;
      }
    }

  }
}


</style>
<style>
.driver-popover.driverjs-theme{
  background-color: #fd9d18;
  color: #fff;
}

.driver-popover.driverjs-theme .driver-popover-title {
  font-size: 20px;
}

.driver-popover.driverjs-theme.driver-popover-title,
.driver-popover.driverjs-theme .driver-popover-description,
.driver-popover.driverjs-theme .driver-popover-progress-text {
  color: #fff;
}

.driver-popover.driverjs-theme button {
  flex: 1;
  text-align: center;
  background-color: #000;
  color: #ffffff;
  border: 2px solid #000;
  text-shadow: none;
  font-size: 14px;
  padding: 5px 8px;
  border-radius: 6px;
}

.driver-popover .driverjs-theme button:hover {
  background-color: #000;
  color: #ffffff;
}

.driver-popover.driverjs-theme .driver-popover-navigation-btns {
  justify-content: space-between;
  gap: 3px;
}

.driver-popover.driverjs-theme .driver-popover-close-btn {
  display: none !important;
}

.driver-popover.driverjs-theme .driver-popover-prev-btn{
  visibility: hidden;
}

.driver-popover.driverjs-theme .driver-popover-close-btn:hover {
  color: #000;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-left.driver-popover-arrow {
  border-left-color: #fd9d18;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-right.driver-popover-arrow {
  border-right-color: #fd9d18;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-top.driver-popover-arrow {
  border-top-color: #fd9d18;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-bottom.driver-popover-arrow {
  border-bottom-color: #fd9d18;
}

</style>
