import server from "../utils/request";
import {EventSourcePolyfill} from "event-source-polyfill";
import store from "@/store";

// v2  mjh 正式环境  dev mj测试环境
// 发送关键字的请求
export function openChat(data) {
    return server.post('v2/chat-api/oaChat', data);
    //return server.post("dev/chat-api/oaChatBy3Dot5", data);
}

export function backChat(data) {
    return server.get("chat-api/bdAiOnline/one", {params: data});
}

// 获取所有聊天的历史记录ƒ
export function getChatHistory(data) {
    return server.post("v2/chat-api/getChatHistory", data);
}

// 获取全部的分类列表
export function getAllTipsList() {
    return server.get("/v2/chat-api/getChatPromptCategorys");
}

// 获取左侧列表的二级分类
export function getSubTipsList(data) {
    return server.post("/v2/api/getCategorysByCategoryName", data);
}

// 新的获取单图下的全部分类
export function getAllTipsListNew() {
    return server.get("/v2/api/getAllCategorys");
}

// 获取每个分类下的详细内容
export function getAllTipsListDetail() {
    return server.get("/v2/api/getCategorysByGroupType");
}

export function getAllCateAndScene(data) {
    return server.post("/v2/chat-api/getPromptsByCategoryAndScene", data);
}

// 文字绘制图像 (测试dev线上要改v2)
export function openImgChat(data) {
    return server.post("/v2/mj-api/imagineByText", data);
}

// 用户名获取图片历史记录列表(测试dev线上要改v2)
export function getImgHistoryByName(data) {
    return server.post("/v2/mjh-api/getMjQuestionsByUserName", data);
}

// 根据triggerId获取Img
export function getImgByTriggerId(data) {
    return server.post("/v2/mjh-api/getQuestionsByUserNameAngTriggerId", data, {
        headers: {"Content-Type": "application/json"},
    });
}

// 根据索引生成选择单图的内容 (测试dev线上要改v2)
export function getImgUpscale(data) {
    return server.post("/v2/mj-api/upscale", data, {
        headers: {"Content-Type": "application/json; charset=UTF-8"},
    });
}

// 获取全部图片提示词
export function getAllImgMjPromptHotKeys() {
    return server.get("/v2/mjh-api/getMjPromptHotKeys");
}

// 获取索引生成单图的内容 (测试dev线上要改v2)
export function getMjQuestionsByUpscale(data) {
    return server.post("/v2/mjh-api/getMjQuestionsByUpscale", data);
}

// 清空历史记录
export function clearChatHistory(data) {
    return server.post("/v2/chat-api/clearChatHistory", data);
}

// 清空历史记录
export function clearImgChatHistory(data) {
    return server.post("/v2/mjh-api/clearMjHistory", data);
}

/*
 * red opearate
 * */

// crawler by keyword
export function RedCrawlerByKeyword(data) {
    return server.post("/v2/red/redCrawlerByKeyword", data);
}

// get crawler's result
/*
export function RedGetCrawlerResult(data, dataLength) {
    //return server.get('/v2/red/getCrawlerResult', data);
    let taskId = '37bb9c1e6173aeb86c602f96c0316c71';
    let userId = 'oLDeNxCFESIY13XhPLYWecOW60L4';
    //let url = `https://e-ai.emarkdigital.tech/v2/red/getCrawlerResult?taskId=${data.taskId}&userId=${data.userId}`;
    let url = `https://e-ai.emarkdigital.tech/v2/red/getCrawlerResultLen?taskId=${taskId}&userId=${userId}`;
    //let url = `http://127.0.0.1:8890/dev/red/getCrawlerResult?taskId=${taskId}&userId=${userId}`;
    let access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MjA3MDU0MDcsInBhc3N3ZCI6Im82WnB5NlY2bjAtYUZsMTNDWmdVRUN2bXlabEkiLCJ1c2VyIjoib0xEZU54QlBnaXZnS3hNSXoxMTMxRlhWUFdyWSJ9._xBwO4YUhwQimEfuu3bwblHxfsPtdXAZ56idQGete9I'
    var es = new EventSourcePolyfill(url, {
        headers: {
            //"Content-Type": "text/event-stream",
            //"Cache-Control": "no-cache",
            //"Connection": "keep-alive",
            "X-Token": access_token,
        },
    });
    es.onopen=function (event) {
        console.log("open 已经开启");
    };
    es.onmessage=function (event) {
        console.log("message", event);
        let data = JSON.parse(event.data);
        resLength.value = data.data;
    };
    es.onerror=function (event){
        console.log("error 获取错误", event);
        es.close();
    };
}*/

// get crawler's result len
export function RedGetCrawlerResultLen(data) {
    return server.get(`/v2/red/getCrawlerResultLen?userId=${data.userId}&taskId=${data.taskId}`);
}


// genrate title by ai
export function RedGenerateTitleByAi(data) {
    return server.post("/v2/red/generateTitleByAi", data);
}

// genrate content by ai
export function RedGenerateContentByAi(data) {
    return server.post("/v2/red/generateContentByAi", data);
}
