import Vue from 'vue';
import VueRouter from "vue-router";

Vue.use(VueRouter);
import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";
import Dashboard from "@/pages/Dashboard.vue";
import UserProfile from "@/pages/UserProfile.vue";
import TableList from "@/pages/TableList.vue";
import Typography from "@/pages/Typography.vue";
import Icons from "@/pages/Icons.vue";
import Maps from "@/pages/Maps.vue";
import Notifications from "@/pages/Notifications.vue";
import UpgradeToPRO from "@/pages/UpgradeToPRO.vue";
import Common from "@/pages/Common.vue";
import CommomUse from "@/pages/CommomUse";
import OfficialBusiness from "@/pages/OfficialBusiness";
import Operate from "@/pages/Operate";
import Program from "@/pages/Program";
import Education from "@/pages/Education";
import Life from "@/pages/Life";
import Detail from "@/pages/Detail";
import ImageDetail from "@/pages/ImageDetail";
import Login from "@/pages/Login";
import RedOperate from "@/pages/RedOperate.vue";


const routes = [
    {
        path: "/",
        name: 'DashboardLayout',
        component: DashboardLayout,
        redirect: "/dashboard",
        children: [
            {
                path: "dashboard",
                name: "Dashboard",
                component: Common,
                level: 0,
            },
            {
                path: "common",
                name: "Common",
                component: Dashboard,
                level: 0,
            },
            {
                path: "user",
                name: "User Profile",
                component: UserProfile,
                level: 0,
            },
            {
                path: "table",
                name: "Table List",
                component: TableList,
                level: 0,
            },
            {
                path: "typography",
                name: "Typography",
                component: Typography,
                level: 0,
            },
            {
                path: "icons",
                name: "Icons",
                component: Icons,
                level: 0,
            },
            {
                path: "maps",
                name: "Maps",
                meta: {
                    hideFooter: true,
                },
                component: Maps,
                level: 0,
            },
            {
                path: "notifications",
                name: "Notifications",
                component: Notifications,
                level: 0,
            },
            {
                path: "upgrade",
                name: "Upgrade to PRO",
                component: UpgradeToPRO,
                level: 0,
            },
            {
                path: "commonUse",
                name: "CommonUse",
                component: CommomUse,
                level: 0,
            },
            {
                path: "officialBusiness",
                name: "OfficialBusiness",
                component: OfficialBusiness,
                level: 0,
            },
            {
                path: "operate",
                name: "Operate",
                component: Operate,
                level: 0,
            },
            {
                path: "program",
                name: "Program",
                component: Program,
                level: 0,
            },
            {
                path: "education",
                name: "Education",
                component: Education,
                level: 0,
            },
            {
                path: "life",
                name: "Life",
                component: Life,
                level: 0,
            },
            {
                path: "/detail",
                name: "Detail",
                component: Detail,
                level: 1,
            },
            {
                path: "/imageDetail",
                name: "ImageDetail",
                component: ImageDetail,
                level: 1,
            },
            {
                path: "/redOperate",
                name: "RedOperate",
                component: RedOperate,
                level: 0,
            }
        ],
    },
    {
        path: "/login",
        name: 'Login',
        component: Login,
    }
];

// configure router
const router = new VueRouter({
    routes, // short for routes: routes
    linkExactActiveClass: "nav-item active",
});
// 1.全局路由守卫
router.beforeEach((to, from, next) => {
    if (to.path === '/login') return next();
    // 获取 token
    const tokenStr = window.sessionStorage.getItem('token');
    if (!tokenStr) return next('/login');
    next();
});


export default router;
