<script setup>
import { ref, onMounted, onUnmounted, watch } from "vue";
import {
  RedCrawlerByKeyword,
  RedGetCrawlerResultLen,
  RedGenerateTitleByAi,
  RedGenerateContentByAi,
} from "@/api";

let userId = ref(""); // 用户ID
let taskId = ref(""); // 任务ID
let resLength = ref(0);
let isRunning = ref(false); // 爬虫结果长度
let crawlerExecCount=ref(0); // 获取结果次数
let timer;

const inputGroups = ref([
  {
    id: "keyword",
    label: "爬虫关键词",
    value: "",
    chineseLabel: "爬虫工作需要时间，请耐心等待。",
  },
  {
    id: "title",
    label: "Title's Prompt",
    value: "",
    chineseLabel: "AI输出标题",
  },
  {
    id: "content",
    label: "Content's Prompt",
    value: "",
    chineseLabel: "AI输出笔记内容",
  },
]);

const executeAction = async (index) => {
  if (!inputGroups.value[index].value.trim()) {
    alert(`请输入${inputGroups.value[index].label}`);
    return;
  }

  const baseReqData = {
    userId: userId.value,
  };

  switch (index) {
    case 0:
      await redCrawlerHandler({
        ...baseReqData,
        keyword: inputGroups.value[0].value,
      });
      break;
    case 1:
    case 2:
      if (!taskId.value) {
        console.log("taskId is null");
        alert("请先执行爬虫任务");
        return;
      }
      const reqData = {
        ...baseReqData,
        taskId: taskId.value,
        prompt: inputGroups.value[index].value,
      };
      index === 1 ? generateTitle(reqData) : generateContent(reqData);
      break;
    default:
      console.log("未知操作");
      break;
  }
};

const redCrawlerHandler = (data) => {
  RedCrawlerByKeyword(data).then((res) => {
    if (res.code === 200) {
      taskId.value = res.data.taskId;
      //console.log("new task id : ", taskId.value);
      let getResData = {
        userId: userId.value,
        taskId: taskId.value,
      };
      console.log("new task id:", taskId.value);
      inputGroups.value[0].chineseLabel = `当前任务: ${taskId.value}  已创建。`;
      isRunning.value = true;
    }
  });
};

const CrawlerResultLengthTask = () => {
  let data = {
    userId: userId.value,
    taskId: taskId.value,
  };
  if (taskId.value == "") {
    console.log("task id is null");
    return;
  }
  redGetResultLength(data);
  if (resLength.value > 10) {
    isRunning.value = false;
  }
};

const redGetResultLength = (data) => {
  RedGetCrawlerResultLen(data).then((res) => {
    if (res.code === 200) {
      resLength.value = res.data.length;
      console.log("res length : ", resLength.value);
      if (resLength.value > 0) {
        inputGroups.value[0].chineseLabel += `\n 爬虫结果已生成，共${resLength.value}条。`;
      }
    }
  });
};

/*onMounted(() => {
  console.log('mounted');
  timer = setInterval(() => {
    CrawlerResultLengthTask();
  }, 5000);
});*/

watch(isRunning, () => {
  if (resLength.value == 0) {
    timer = setInterval(() => {
      CrawlerResultLengthTask();
    }, 5000);
  } else {
    clearInterval(timer);
  }
});

// TODO: 未实现,等待时间较长，后端需要跟进sse
/*
const getCrawlerResult = (data) => {
  RedGetCrawlerResult(data).then(res => {
    if (res.code === 200) {
      resLength.value = res.data.length;
      console.log("res length : ", resLength.value);
      if (resLength.value > 0) {
        inputGroups.value[0].chineseLabel = `爬虫结果已生成，共${resLength.value}条。`;
      }
    }
  });
};*/

const generateTitle = (data) => {
  RedGenerateTitleByAi(data).then((res) => {
    console.log(res);
    if (res.code === 200) {
      inputGroups.value[1].chineseLabel = res.data;
    } else {
      alert(res.message);
    }
  });
};

const generateContent = (data) => {
  RedGenerateContentByAi(data).then((res) => {
    console.log(res);
    if (res.code === 200) {
      inputGroups.value[2].chineseLabel = res.data;
    } else {
      alert(res.message);
    }
  });
};

// init user's id
const initUserId = () => {
  console.log("initUserId");
  userId.value = localStorage.getItem("userName");
  console.log("user's id : ", userId.value);
};

initUserId();
</script>

<template>
  <div class="container">
    <div
      v-for="(item, index) in inputGroups"
      :key="index"
      class="item-container"
    >
      <div class="input-group">
        <label :for="item.id">{{ item.label }}:</label>
        <input type="text" :id="item.id" v-model="item.value" />
        <button @click="executeAction(index)">执行</button>
        <div class="chinese-label">{{ item.chineseLabel }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container {
  font-family: Arial, sans-serif;
  max-width: 700px;
  margin: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.item-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

label {
  margin-bottom: 5px;
}

input[type="text"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
}

button {
  padding: 8px 15px;
  background-color: #66b3ff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  align-self: flex-start;
  margin-bottom: 10px;
}

.chinese-label {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 10px;
}

.divider {
  margin-top: 10px;
  color: #666;
}
</style>
