<template>
  <div class="darw-out-box">
    <div class="darw-out-img-box" v-show="isFirst">
      <img v-imgLazy="imgSrc" class="draw-all-img"/>
      <div class="draw-one-box-img">
        <div class="draw-one-img" :key="index" v-for="(item,index) in arr" @click="chooseItem(item)">
          <div class="spinBox" v-if="item.isSpin">
            <md-progress-spinner md-mode="indeterminate" class="draw-one-img-spinner"></md-progress-spinner>
          </div>
        </div>
      </div>
      <div class="draw-out-box-close">
        <span @click="closeDrawBox">
          <md-icon class="edit">close</md-icon>
        </span>
      </div>

    </div>
    <div class="darw-out-img-box" v-show="!isFirst">
      <span class="downBtn"  @click="downLoad(imgSrcDetail)">下载图片</span>
      <img v-imgLazy="imgSrcDetail" class="draw-all-img"/>
      <div class="draw-out-box-close">
        <span @click="closeDrawBox">
          <md-icon class="edit">close</md-icon>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import * as API from '@/api/index';
import {getImgUpscale} from "@/api/index";
import imgLazy from '@/directives/index';
import store from "@/store";
import { EventSourcePolyfill } from 'event-source-polyfill';

export default {
  name: "image",
  props: {
    imgSrc: [String,Array],
    activeMsgHash: [String,Number],
    activeTriggerId: [String,Number],
    activeMsgId: [String,Number]
  },
  directives: {
    imgLazy: imgLazy,
  },
  data(){
    return{
      arr:[{itemIndex:1,isSpin:false},{itemIndex:2,isSpin:false},{itemIndex:3,isSpin:false},{itemIndex:4,isSpin:false}],
      isFirst: true,
      imgSrcDetail: '',
      newTriggerId: '',
      timerInterval: null,
      activeProgress: 0,
    }
  },
  watch: {
    activeProgress: {
      handler(newVal, oldVal) {
       if(newVal == 100){
         clearInterval(this.timerInterval)
       }
      },
      immediate: true,
      deep: true,
    }
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  },
  methods:{
    downLoad(){
      window.open(this.imgSrcDetail)
    },
    chooseItem(item){
      var obj = {
        msg_id: this.activeMsgId,
        msg_hash: this.activeMsgHash,
        trigger_id: this.activeTriggerId,
        index: item.itemIndex,
        userName: store.state.userName,
      }
      API.getImgUpscale(obj).then(res=>{
        this.newTriggerId = res.trigger_id
        item.isSpin = true
        var imgES = new EventSourcePolyfill(`https://e-ai.emarkdigital.tech/dev/mjh-api/getMjQuestionsByTaskType?userName=${store.state.userName}&triggerId=${this.newTriggerId }&taskType=upscale`,
            {
              headers: {
                'Content-Type': 'text/event-stream',
                'Cache-Control': 'no-cache',
                'Connection': 'keep-alive',
                'X-Token': `${store.state.token}`
              }
            })
        imgES.addEventListener('open',()=>{
          console.log("已开启。。。");
        })
        imgES.addEventListener('message',(e)=>{
          var arr = JSON.parse(e.data)
          if(arr.code == 200){
            this.activeProgress = arr.data.Progress.String
            if(arr.data.Progress.String == 100){
              // if(arr.data.MsgHash !== this.activeMsgHash){
                this.isFirst = false
                this.imgSrcDetail = arr.data.MjAnswer.String
                item.isSpin = false
                this.$forceUpdate()
                imgES.close();
              // }
            }
          }
        })
        imgES.addEventListener('error',(e)=>{
          console.log(e,'失败的时候！！！')
          // imgES.close();
        })
        // this.getImgDetail(res.trigger_id,item)
      }).catch(err => {
        alert(err)
      })
    },
    getImgDetail(trigger_id,item){
      var obj = {
        userName: store.state.userName,
        triggerId: parseInt(trigger_id),
      }
      API.getMjQuestionsByUpscale(obj).then(res=>{
        if(res.message == 'success'){
          // hash 值不能和之前的一样
          if((res.data.MsgHash.String !== this.activeMsgHash)){
            this.activeProgress = res.data.Progress.String
            // Progress 进度要显示100%
            if(res.data.Progress.String && res.data.Progress.String ==100) {
              this.isFirst = false
              this.imgSrcDetail = res.data.MjAnswer.String
              item.isSpin = false
              this.$forceUpdate()
            }
          }else{
            this.timerInterval = setInterval(()=>{
              this.getImgDetail(this.newTriggerId,item)
            },5000)
          }
        } else {
          alert(res.message)
        }
      }).catch(err => {
        alert(err)
      })
    },
    closeDrawBox(){
      clearInterval(this.timerInterval)
      this.$emit('closeDraw',false)
    }
  }
}
</script>

<style scoped lang="scss">
.darw-out-box{
  position: fixed;
  inset: 0px;
  z-index: 9999;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: rgb(0, 0, 0);
  .darw-out-img-box{
    background-color: rgba(255, 255, 255, 0.2);
    position: relative;
    width: fit-content;
    height: auto;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    .draw-all-img{
      height: auto;
      max-height: 100vh;
      transition: transform 0.3s ease 0s;
    }
    .draw-one-box-img{
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        flex-wrap: wrap;
      }
    .draw-one-img{
      width: 50%;
      height: 50%;
      cursor: pointer;
      transition: all 0.3s ease-in-out 0s;
      box-sizing: border-box;
    }
    .draw-one-img:hover{
      background-color: rgba(0, 0, 0, 0.3);
      border: 10px solid rgb(255, 255, 255);
      cursor: pointer;
    }
    .draw-out-box-close{
      position: absolute;
      bottom: 40px;
      background: rgba(255, 255, 255, 0.6);
      color: #0c0a06;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;
      span{
        //user-select: none;
        //width: 1em;
        //height: 1em;
        //display: inline-block;
        //fill: currentcolor;
        //flex-shrink: 0;
        //font-size: 40px;
        //color: rgba(255, 255, 255, 0.2);
        font-weight: bold;
        transition: all 0.3s ease-in-out 0s;
      }
    }
    .draw-out-box-close:hover{
      background: rgba(255, 255, 255, 1);
      color: #fff;
    }
  }
}
.downBtn{
  padding: 5px 10px;
  background: rgb(59, 178, 255);
  color: #fff;
  position: absolute;
  right: 20px;
  top: 20px;
  border-radius: 20px;
  cursor: pointer;
}
.spinBox{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: rgb(0,0,0,.5);
  justify-content: center;
}
</style>
