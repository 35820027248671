import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        token:sessionStorage.getItem('token')||'',
        userName:sessionStorage.getItem('userName')||'',
        wechatNickname: sessionStorage.getItem('wechatNickname')||'',
    },
    mutations: {
        SET_TOKEN(state,token){
            state.token = token;
            sessionStorage.setItem('token', token);
        },
        SET_USERNAME(state,userName){
            state.userName = userName;
            sessionStorage.setItem('userName', userName);
        },
        SET_WECHATNICKNAME(state,wechatNickname){
            state.wechatNickname = wechatNickname;
            sessionStorage.setItem('wechatNickname', wechatNickname);
        },
    },
    actions: {
    },
    getters: {
    },
    modules: {
    }
});
