import axios from 'axios';
import JSONBIG from 'json-bigint';
import store from "@/store";
import router from "@/routes/routes";

let baseURL = 'https://e-ai.emarkdigital.tech/'; //接口请求的域名(生产和测试环境一般不同)


const server = axios.create({//创建axios实例
    baseURL: baseURL,
    timeout: 6000000,
    transformResponse: [data => {
        const json = JSONBIG({
            storeAsString: true
        });
        return json.parse(data);
    }]
});


// 添加请求拦截器
server.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    if (store.state && store.state.token) {
        config.headers["Content-Type"] = "application/json;charset=utf-8";
        config.headers["X-Token"] = `${store.state.token}`;
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 响应拦截器
server.interceptors.response.use(function (response) {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    if (response.status === 200) {
        switch (response.data.code) {
            // 401: 未登录
            // 未登录则跳转登录页面，并携带当前页面的路径
            // 在登录成功后返回当前页面，这一步需要在登录页操作。
            case 400:
                alert(response.data.message);
                if(store.state && store.state.token){
                    store.commit('userName', '');
                    store.commit('token', '');
                    store.commit('wechatNickname', '');
                }
                if(sessionStorage.getItem('userName')){
                    sessionStorage.removeItem('userName');
                }
                if(sessionStorage.getItem('token')){
                    sessionStorage.removeItem('token');
                }
                if(sessionStorage.getItem('wechatNickname')){
                    sessionStorage.removeItem('wechatNickname');
                }
                router.push('/login');
                break;

            // 403 token过期
            // 登录过期对用户进行提示
            // 清除本地token和清空vuex中token对象
            // 跳转登录页面
            case 403:
                alert('登录过期，请重新登录');
                // 清除token
                if(store.state && store.state.token){
                    store.commit('userName', '');
                    store.commit('token', '');
                    store.commit('wechatNickname', '');
                }
                sessionStorage.removeItem('userName');
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('wechatNickname');
                break;

            // 404请求不存在
            case 404:
                alert('网络请求不存在');
                break;
            // 其他错误
            default:
                return Promise.resolve(response.data);
        }
    } else {
        return Promise.reject(response);
    }
}, function (error) {
    // 对响应错误处理
    if (error.response.status) {
        switch (error.response.status) {
            // 401: 未登录
            // 未登录则跳转登录页面，并携带当前页面的路径
            // 在登录成功后返回当前页面，这一步需要在登录页操作。
            case 400:
                alert('登录过期，请重新登录');
                store.commit('userName', '');
                store.commit('token', '');
                sessionStorage.removeItem('userName');
                sessionStorage.removeItem('token');
                break;

            // 403 token过期
            // 登录过期对用户进行提示
            // 清除本地token和清空vuex中token对象
            // 跳转登录页面
            case 403:
                alert('登录过期，请重新登录');
                // 清除token
                store.commit('userName', '');
                store.commit('token', '');
                sessionStorage.removeItem('userName');
                sessionStorage.removeItem('token');
                break;

            // 404请求不存在
            case 404:
                alert('网络请求不存在');
                break;
            // 其他错误
            default:
                alert(error.response.data.message);
        }
        return Promise.reject(error.response);
    }
});

export default server;
